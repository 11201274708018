import styles from './PermissionModal.module.css';


const PermissionModal = ({setUserModalPage}) => {
    const handlePermissionButtonClicked = () =>{
        window.flutter_inappwebview.callHandler('CustomChannel', 'REQUEST_PERMISSION');
        setUserModalPage(0)
    };

    return (
        <div className={styles.container}>
                <div className={styles.contents}>
                    <div className={styles.hedaer}>
                            <div className={styles.mainheaderParent}>
                                <b className={styles.mainheader}>
                                        <span>권한 허용</span>
                                        <span className={styles.span}>을 부탁드려요 :)</span>
                                </b>
                                <div className={styles.subheader}>
                                        <p className={styles.hotple}>HOTPLE의 모든 기능을 활용하기 위해선</p>
                                        <p className={styles.hotple}>아래 2가지 권한이 필요해요!</p>
                                </div>
                            </div>
                            <div className={styles.frameParent}>
                                <div className={styles.permissionParent}>
                                        <div className={styles.permission}>
                                            <img className={styles.iconmyLocation} alt="" src="images/my_location.svg" />
                                            <div className={styles.div}>위치 권한</div>
                                        </div>
                                        <div className={styles.parent}>
                                            <div className={styles.pick}>· 위치 기반 핫플 찾기</div>
                                            <div className={styles.pick}>· 내 주변 핫플 추천</div>
                                        </div>
                                </div>
                                <div className={styles.permissionParent}>
                                        <div className={styles.permission}>
                                            <img className={styles.iconmyLocation} alt="" src="images/notification.svg" />
                                            <div className={styles.div}>알림 권한</div>
                                        </div>
                                        <div className={styles.parent}>
                                            <div className={styles.pick}>· 내가 PICK한 핫플</div>
                                            <div className={styles.pick}>· 친구가 태그한 핫플</div>
                                            <div className={styles.pick}>· 신규 이벤트/깜짝 쿠폰</div>
                                        </div>
                                </div>
                            </div>
                    </div>
                    <div className={styles.buttons}>
                            <div className={styles.cancleButton} onClick={() => setUserModalPage(0)}>
                                <div className={styles.text}>돌아가기</div>
                            </div>
                            <div className={styles.deleteButton} onClick={() => handlePermissionButtonClicked()}>
                                <img className={styles.iconsetting} alt="" src="images/setting.svg" />
                                <div className={styles.div}>권한 허용하러 가기</div>
                            </div>
                    </div>
                </div>
        </div>
    );
};

export default PermissionModal;