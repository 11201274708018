import { useEffect, useState } from 'react';
import styles from './ConnectInstagramModal.module.css';
import api from '../../../../api'
import { copyToClipboard } from '../../../../util';

const ConnectInstagramModal = () => {
      const [code, setCode] = useState('- - - - - -');
      const [time, setTime] = useState(0)

      const callGetCodeAPI = async () => {
		try {
			const response = await api.get('/user/instagram/get_code', {
                        params: {
                              type: "connect",
			      }
                  });

                  const connectCode = response.data.code;
                  const expireAt = response.data.expireAt;
			
                  if(connectCode){
                        setCode(connectCode);
                        setTime(expireAt);
                  }
		} catch (error) {
			console.error('There was an error registering the user!', error);
		}
	};

      const handleConnectButtonClick = async () => {
            try {
                await copyToClipboard(code);
        
                alert('코드가 클립보드에 복사되었어요!\n핫플 계정 팔로우 후, 복사된 코드를 DM으로 보내주세요😊');

                window.open(`https://instagram.com/hotple_kr`, '_blank');
            } catch (error) {
                console.error('Error handling connect button click:', error);
            }
        };

      useEffect(()=>{
            callGetCodeAPI();
      },[]);

      useEffect(() => {
            if (time > 0) {
              const timer = setInterval(() => {
                setTime((prevTime) => {
                  if (prevTime <= 1) {
                    clearInterval(timer);
                    setCode('- - - - - -');
                    callGetCodeAPI();
                    return 0;
                  }
                  return prevTime - 1;
                });
              }, 1000);
        
              return () => clearInterval(timer);
            }
      }, [time]);

      return (
            <div className={styles.container}>
                  <div className={styles.header}>
                        <div className={styles.contents}>
                              <b className={styles.title}>인스타그램 계정 연결</b>
                              <div className={styles.innerContents}>
                                    <div className={styles.description}>
                                          <p className={styles.hotpleDm}>
                                                <b className={styles.hotple}>HOTPLE 공식 인스타 팔로우</b>
                                                <span className={styles.span}> 한 뒤,</span>
                                          </p>
                                          <p className={styles.a}>
                                                <b className={styles.a1}>DM으로 인증 코드</b>
                                                <span className={styles.span1}> 만 보내면 끝!</span>
                                          </p>
                                    </div>
                                    <div className={styles.codeFrame}>
                                          <div className={styles.text1}>{`${Math.floor(time / 60).toString().padStart(2, '0')}:${(time % 60).toString().padStart(2, '0')}`}</div>
                                          <div className={styles.codeText}>{code}</div>
                                    </div>
                                    <div className={styles.dmButton} onClick={() => handleConnectButtonClick()}>
                                          <div className={styles.text}>인증코드 DM 보내러가기</div>
                                          <div className={styles.text1}>@hotple_kr</div>
                                    </div>
                              </div>
                        </div>
                        <div className={styles.detail}>
                              <ul className={styles.id}>
                                    <li className={styles.li}>
                                          <span className={styles.id2}>
                                                <span className={styles.id3}>꼭 ‘인증코드’만 DM 보내주세요!</span>
                                          </span>
                                          <span>
                                                <span className={styles.id2}>{` `}</span>
                                                <span>/ 타 문구 포함 시 인식 불가</span>
                                          </span>
                                    </li>
                                    <li className={styles.li}>
                                          <span>
                                                <span>본 서비스는 </span>
                                          </span>
                                          <span className={styles.id2}>
                                                <span className={styles.id3}>공식 인스타 팔로워 분들에게만</span>
                                          </span>
                                          <span>
                                                <span> 제공됩니다😉</span>
                                          </span>
                                    </li>
                              </ul>
                        </div>
                  </div>
            </div>
      );
};

export default ConnectInstagramModal;