import styles from './SearchHeader.module.css';
import React, { useState, useEffect, useRef } from 'react';
import {callSearchingPlaces} from '../../../api'
import { isMobileSafari } from 'react-device-detect';


const SearchHeader = ({selectedOption, setSelectedOption, setMapLocation, setIsSearchMode, marginTop}) => {
	const regionList = ['전국','서울','경기','인천','강원','대구','경북','경남','부산','울산','전북','전남','광주','충북','충남','대전','제주','세종'];
    const [query, setQuery] = useState('');
    const [places, setPlaces] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);
    const searchFrameRef = useRef(null);

    useEffect(() => {
        let id;

        const isKoreanSyllable = (char) => {
            const code = char.charCodeAt(0);
            return code >= 0xAC00 && code <= 0xD7A3;
        };
    
        const isValidQuery = (query) => {
            if(query[query.length - 1] == ' ')
                return false;

            for (let char of query) {
                if (!isKoreanSyllable(char) && /[ㄱ-ㅎㅏ-ㅣ]/.test(char)) {
                    return false;
                }
            }
            return true;
        };
    
        if (query) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
    
            if (isValidQuery(query)) {
                id = setTimeout(() => {
                    fetchPlaces(query);
                }, 300);
            }
    
            setTimeoutId(id);
        } else {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setPlaces([]);
        }
    
        return () => {
            if (id) {
                clearTimeout(id);
            }
        };
    }, [query]);

	useEffect(() => {
		if(isMobileSafari)
			document.getElementById('placeInput').focus();
		else{
			setTimeout(() => {
				const inputElement = document.getElementById('placeInput');
				if (inputElement) {
					inputElement.focus();
				}
			}, 0);
		}
	}, []);

	const fetchPlaces = async (keyword) => {
        const results = await callSearchingPlaces(keyword);
        if(results.length>0)
            setPlaces(results);
    };

    const handleRegionClick = (region) => {
        setSelectedOption((prev)=>({...prev, region: region}));
        setIsSearchMode(false);
    };

    const handlePlaceClick = (place) => {
        setSelectedOption((prev) => ({
            ...prev,
            region: '위치',
        }));
        setMapLocation({
            name: place.place_name,
            address: place.address_name.split(' ').slice(0, 3).join(' '),
            latitude: place.y,
            longitude: place.x,
        });
        setIsSearchMode(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchFrameRef.current && !searchFrameRef.current.contains(event.target)) {
                setIsSearchMode(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchFrameRef]);

  	return (
    		<div style={{marginTop: `${marginTop+16}px`}} className={styles.searchheader} ref={searchFrameRef}>
      			<div className={styles.searchframe}>
        				<div className={styles.div}>
                            <input
                                type="text"
                                id="placeInput"
                                className={styles.input}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="어디 주변 핫플을 찾으세요?"
                                autoComplete="off"
                            />
        				</div>
        				<div className={styles.right}>
          					<div className={styles.div2}>
            						<img className={styles.iconsearch} alt="" src="images/search.svg" />
          					</div>
        				</div>
      			</div>
      			<div className={styles.searchheaderChild} />
      			<div className={styles.bottomframe}>
                    {query=='' &&
        				<div className={styles.regionbuttons}>
							{regionList.map(region => (
								<div key={region} className={`${selectedOption.region === region || (region=='전국'&&selectedOption.region=='위치') ? styles.regionButtonSelected : styles.regionButton}`} onClick={() => handleRegionClick(region)}>
										<div className={styles.text}>{region}</div>
								</div>
							))}
        				</div>
                    }

                    {places.length > 0 && (
                        <>
                            {places.map((place) => (
                                <div className={styles.searchresult} key={place.id} onClick={() => handlePlaceClick(place)}>
                                    <div className={styles.leftinfo}>
                                        <div className={styles.placename}>{place.place_name}</div>
                                        <div className={styles.placeaddress}>{place.road_address_name || place.address_name}</div>
                                    </div>
                                    <div className={styles.category}>{place.category_name.split(/[,>]/).pop().trim()}</div>
                                </div>
                            ))}
                        </>
                    )}
      			</div>
    		</div>);
};

export default SearchHeader;
