import api from '../../../../api';
import styles from './AdminHeader.module.css';
import React, { useEffect, useState } from 'react';

const AdminHeader = ({setAuthModalPage, setInfoModalPage, notices, account, setSelectedOption, adminHotples, updateHotpleInfo, fcmToken, setModalProps}) => {
	const [userCount, setUserCount] = useState({allUsersCount:0, newUsersCount:0});
	const [inquiryCount, setInquiryCount] = useState({allInquiryCount:0, notProcessedInquiryCount:0});

    const handleLogout = async () => {
		setModalProps({fcmToken: fcmToken});
		setAuthModalPage(4);
    };

	const handleEditNoticeButtonClick = async () => {
		setInfoModalPage(11);
	}

	const handleUserMonitorButtonClick = async () => {
		setInfoModalPage(12);
	}

	const handleInquiryMonitorButtonClick = async () => {
		setInfoModalPage(41);
	}

	const handleAddHotpleManuallyClick = async () => {
		try {
            const confirmAdd = window.confirm(`빈 핫플이 생성됩니다. 계속하시겠습니까?`);
    
            if (confirmAdd) {
                const response = await api.post(`/admin/hotple/add_hotple`);
    
                updateHotpleInfo("AddNewHotpleAndEdit", response.data.hotples[0]);
            }
        } catch (error) {
            console.error('Error deleting notice:', error);
        }
	}

	const handleHotpleInfoClicked = (filter) => {
		setSelectedOption(prev => ({ ...prev, filter: filter }));
	}

	const fetchUserCount = async () => {
        try {
            const response = await api.get('/admin/users/count');
            if (response.status === 200) {
                const data = response.data;
                setUserCount({
                    allUsersCount: data.allUsersCount,
                    newUsersCount: data.newUsersCount
                });
            } else {
                console.error('Failed to fetch user count');
            }
        } catch (error) {
            console.error('Error fetching user count:', error);
        }
    };

	const fetchInquiryCount = async () => {
        try {
            const response = await api.get('/admin/inquiry/count');
            if (response.status === 200) {
                const data = response.data;
                setInquiryCount({
                    allInquiryCount: data.allInquiryCount,
                    notProcessedInquiryCount: data.notProcessedInquiryCount
                });
            } else {
                console.error('Failed to fetch inquiry count');
            }
        } catch (error) {
            console.error('Error fetching inquiry count:', error);
        }
    };

    // useEffect에서 fetchUserCount 호출
    useEffect(() => {
        fetchUserCount();
		fetchInquiryCount();
    }, []);

  	return (
    		<div className={styles.userheader}>
				<div className={styles.accountinfo}>
					<div className={styles.hotpleaccountinfo}>
						<div className={styles.div}>관리자 계정</div>
						<div className={styles.hotpleaccountid}>{account.hotpleId}</div>
						<div className={styles.logoutbutton} onClick={handleLogout}>로그아웃</div>
					</div>
				</div>
				<div className={styles.buttonLayout2}>
					<div className={styles.hotpleinfos}>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('all')}>
							<div className={styles.pick1}>총 핫플</div>
							<div className={styles.myhotplenum}>{adminHotples.hotples ? adminHotples.hotples.length : 0}개</div>
						</div>
						<div className={styles.verticalDivider}/>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('all')}>
							<div className={styles.pick2}>새 핫플</div>
							<div className={styles.myhotplenum2}>
								{adminHotples.hotples ? adminHotples.hotples.filter(hotple => {
									const createdAt = new Date(hotple.created_at);
									const oneMonthAgo = new Date();
									oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

									return createdAt >= oneMonthAgo;
								}).length : 0}개
							</div>
						</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('need_verify')}>
							<div className={styles.pick2}>검토 필요</div>
							<div className={styles.myhotplenum2}>{adminHotples.hotples ? adminHotples.hotples.filter(hotple => hotple.need_verify).length : 0}개</div>
						</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('edit_request')}>
							<div className={styles.pick2}>수정 요청</div>
							<div className={styles.myhotplenum2}>{adminHotples.hotples ? adminHotples.hotples.filter(hotple => hotple.verify_results && hotple.verify_results.length>0).length : 0}개</div>
						</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('err_image')}>
							<div className={styles.pick2}>오류</div>
							<div className={styles.myhotplenum2}>{adminHotples.hotples ?
							adminHotples.hotples.filter(hotple => !hotple.preview_url || !hotple.contents_url || hotple.contents_url.length==0).length +
							adminHotples.hotples.filter(hotple => !hotple.name || !hotple.caption || !hotple.main_category || !hotple.sub_category1 || !hotple.sub_category2 || !hotple.tags || !hotple.place_name || !hotple.address || !hotple.region || !hotple.latitude || !hotple.longitude).length +
							adminHotples.hotples.filter(hotple => hotple.address && (hotple.region != hotple.address.substring(0,2))).length
							: 0}개</div>
						</div>
					</div>
					<div className={styles.buttonLayout}>
						<div className={styles.adminButtons}>
							<div className={styles.connectinstagrambutton2} onClick={handleEditNoticeButtonClick}>
								<div className={styles.text}>배너 {notices.length}개 / 팝업 {notices.filter(notice=>notice.popup_image_url).length}개</div>
							</div>
							<div className={styles.connectinstagrambutton2} onClick={handleUserMonitorButtonClick}>
								<div className={styles.text}>유저 {userCount.allUsersCount}명 / 신규 {userCount.newUsersCount}명</div>
							</div>
						</div>
						<div className={styles.adminButtons}>
							<div className={styles.connectinstagrambutton2} onClick={handleInquiryMonitorButtonClick}>
								<div className={styles.text}>문의 {inquiryCount.allInquiryCount}개 / 미답변 {inquiryCount.notProcessedInquiryCount}개</div>
							</div>
							<div className={styles.connectinstagrambutton2} onClick={handleAddHotpleManuallyClick}>
								<div className={styles.text}>핫플 수동 등록</div>
							</div>
						</div>
					</div>
				</div>
    		</div>);
};

export default AdminHeader;
