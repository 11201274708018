let MIN_Y = 16;
let MAX_Y = window.innerHeight - 96;
let MID_Y = window.innerHeight / 2; // 반쯤 펼친 상태
let BOTTOM_SHEET_HEIGHT = window.innerHeight - MIN_Y - 50;


function set_MIN_Y(marginTop) {
  MIN_Y = 16 + marginTop; // 초기 값에 marginTop 적용
}

function get_MIN_Y() {
  return MIN_Y;
}


function set_MAX_Y(height) {
  MAX_Y = height - 96;
}

function get_MAX_Y() {
  return MAX_Y;
}


function set_MID_Y(height) {
  MID_Y = height / 2;
}

function get_MID_Y() {
  return MID_Y;
}

function set_BOTTOM_SHEET_HEIGHT(height) {
  BOTTOM_SHEET_HEIGHT = height - MIN_Y - 50;
}

function get_BOTTOM_SHEET_HEIGHT() {
  return BOTTOM_SHEET_HEIGHT;
}

// 모듈 export
module.exports = {
  set_MIN_Y,
  get_MIN_Y,
  set_MAX_Y,
  get_MAX_Y,
  set_MID_Y,
  get_MID_Y,
  set_BOTTOM_SHEET_HEIGHT,
  get_BOTTOM_SHEET_HEIGHT,
};