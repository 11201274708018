import api from '../../../../api';
import styles from './LogoutModal.module.css';


const LogoutModal = ({setAuthModalPage, setAccount, fcmToken}) => {
    const handleLogout = async () => {
        setAuthModalPage(0);
		try{
			const refreshToken = localStorage.getItem('refreshToken');
			const response = await api.post(`/auth/logout/`, {
				refreshToken,
                fcmToken
			});
		}catch(error){
			console.log(error);
		}
        setAccount({hotpleId:null, instagramUsername:null});
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };

  	return (
        <div className={styles.container}>
            <div className={styles.container2}>
                <div className={styles.contents}>
                    <div className={styles.hedaer}>
                        <b className={styles.mainheader}>
                            <span>정말 </span>
                            <span className={styles.span}>로그아웃</span>
                            <span> 하시겠어요?</span>
                        </b>
                        <div className={styles.subheader}>
                            <p className={styles.p}>로그아웃 시,</p>
                            <p className={styles.p1}>
                                    <span>자동 로그인이 해제</span>
                                    <span className={styles.span1}>됩니다.</span>
                            </p>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.cancleButton} onClick={()=>setAuthModalPage(0)}>
                                <div className={styles.text}>취소</div>
                        </div>
                        <div className={styles.logoutButton} onClick={()=>handleLogout()}>
                                <div className={styles.text}>로그아웃</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;