import React, { useEffect, useRef, useState } from 'react';
import './NaverMap.css';
import MapHeader from './MapHeader/MapHeader';
import MarkerClustering from './MarkerClustering';
import api from '../../api';
import SearchHeader from './SearchHeader/SearchHeader';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { shareContent } from '../../util';


const { naver } = window;

const NaverMap = ({ activeMenu, setActiveMenu, selectedOption, setSelectedOption, myHotples, mapHotples, setRangeHotples, setRecommendHotples, mapLocation, setMapLocation, isSearchMode, setIsSearchMode, selectedHotple, setSelectedHotple, setInfoModalPage, periodHotpleGroup, marginTop, platform}) => {
  const mapElement = useRef(null);
  const [initialRender, setInitialRender] = useState(true);
  const [isMapLoad, setIsMapLoad] = useState(false);
  const [devicePosition, setDevicePosition] = useState({lat:37.5695704, long:126.978399});
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [infoWindow, setInfoWindow] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [rangeCircle, setRangeCircle] = useState(null);
  const [positionMarker, setPositionMarker] = useState(null);
  const [searchButtonFlag, setSearchButtonFlag] = useState(false);

  const regionCoordinates = {
    "서울": [37.5666103, 126.9783882],
    "부산": [35.179816, 129.0750223],
    "대구": [35.87139, 128.601763],
    "대전": [36.3504396, 127.3849508],
    "광주": [35.160032, 126.851338],
    "경기": [37.4363177, 127.550802],
    "인천": [37.4562447, 126.7052086],
    "전북": [35.6910153, 127.2368291],
    "전남": [34.9007274, 126.9571667],
    "충북": [36.7853718, 127.6551404],
    "충남": [36.6173379, 126.8453965],
    "경북": [36.6308397, 128.962578],
    "경남": [35.4414209, 128.2417453],
    "강원": [37.8603672, 128.3115261],
    "제주": [33.4273366, 126.5758344],
    "울산": [35.5394773, 129.3112994],
    "세종": [36.4803512, 127.2894325]
};

  useEffect(() => {
    if (!mapElement.current || !naver) return;
    const params = new URLSearchParams(window.location.search);
    const menu = params.get('menu');
    const getRecommendHotpleFlag = params.toString().length == 0 || menu == 'search';
    console.log(getRecommendHotpleFlag);

    const mapOptions = {
      center: new naver.maps.LatLng(37.5695704, 126.978399),
      zoom: 12,
      logoControlOptions: {
        position: naver.maps.Position.BOTTOM_LEFT,
      },
      disableKineticPan: false,
    };

    const mapInstance = new naver.maps.Map(mapElement.current, mapOptions);
    setMap(mapInstance);

    // 현재 위치 가져오기
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = new naver.maps.LatLng(latitude, longitude);

          if(getRecommendHotpleFlag){
            mapInstance.setCenter(currentLocation);
            getRecommendHotple(latitude, longitude);
          }
          
          setDevicePosition({lat:latitude, long:longitude});
          setIsMapLoad(true);
        },
        (error) => {
          console.error('Error fetching geolocation:', error);
          setIsMapLoad(true);
          if(getRecommendHotpleFlag){
            getRecommendHotple();
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setIsMapLoad(true);
    }
    document.body.style.overflow = 'hidden';
  }, []);

  function moveToCurrentLocation(){
    if (!mapElement.current || !naver) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDevicePosition({lat:latitude, long:longitude});
          const currentLocation = new naver.maps.LatLng(latitude, longitude);
          map.panTo(currentLocation);
        },
        (error) => {
          console.error('Error fetching geolocation:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }

  function increaseZoomLevel() {
    if (!mapElement.current || !naver) return;
    
    const currentZoom = map.getZoom();  // 현재 줌 레벨 가져오기
    map.setZoom(currentZoom + 1, { animate: true });      // 줌 레벨 1 증가
  }

  function decreaseZoomLevel() {
    if (!mapElement.current || !naver) return;
    
    const currentZoom = map.getZoom();  // 현재 줌 레벨 가져오기
    map.setZoom(currentZoom - 1, { animate: true });      // 줌 레벨 1 감소
  }

  function haversineDistance(coord1, coord2) {
    const R = 6371; // 지구 반경(km)
    const lat1 = coord1.lat();
    const lon1 = coord1.lng();
    const lat2 = coord2.lat();
    const lon2 = coord2.lng();
  
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
  
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c; // 두 좌표 간의 거리(km)
    return distance;
  }

  function getMapRange() {
    const bounds = map.getBounds();

    const southWest = bounds.getSW(); // 남서쪽 좌표 (Latitude, Longitude)
    const northEast = bounds.getNE(); // 북동쪽 좌표 (Latitude, Longitude)
    
    // 좌우 거리 (동서 거리)
    const horizontalDistance = haversineDistance(
      new naver.maps.LatLng(southWest.lat(), southWest.lng()),
      new naver.maps.LatLng(southWest.lat(), northEast.lng())
    );
    
    // 위아래 거리 (남북 거리)
    const verticalDistance = haversineDistance(
      new naver.maps.LatLng(southWest.lat(), southWest.lng()),
      new naver.maps.LatLng(northEast.lat(), southWest.lng())
    );
    
    // 더 큰 거리 반환
    return Math.max(horizontalDistance, verticalDistance);
  }

  const clearMarkers = () => {
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);
  };

  const whenSearchButtonClicked = () => {
    setActiveMenu('search');
    setSearchButtonFlag(false);
    if(selectedOption.category.startsWith('@'))
      setSelectedOption((prev)=>({...prev, category: 'recommend'}));
    else
      getRangeHotple();
  };

  const showHotpleDetail = (hotple) => {
    const { latitude, longitude } = hotple;
    const position = new naver.maps.LatLng(latitude, longitude);

    if (infoWindow) {
      infoWindow.close();
    }

    const newInfoWindow = new naver.maps.InfoWindow({
        content: `
          <div class="infoframe">
            ${
              hotple.media_url
                ? `
                <a class="instagrambutton" href="${hotple.media_url}" target="_blank" rel="noopener noreferrer">
                  <img class="iconinstagram" alt="" src="images/instagram.svg" />
                  <div class="instagramtext">게시글 이동</div>
                </a>
                <div class="divider"></div>
                `
                : ""
            }
            <div class="divider"></div>
            <a class="routebutton">
              <img class="iconcourse" alt="" src="images/course.svg" />
              <div class="instagramtext">경로 안내</div>
            </a>
            <div class="divider"></div>
            <a class="sharebutton">
              <img class="iconshare" alt="" src="images/share.svg" />
              <div class="instagramtext">공유</div>
            </a>
          </div>
        `,  // 렌더링된 React 컴포넌트가 포함된 div를 설정
        backgroundColor: "transparent",
        borderColor: "transparent",
        pixelOffset : new naver.maps.Point(0, -45),
        borderWidth: 0,
        disableAnchor: true,
        anchorColor: "#fff",
    });

    naver.maps.Event.addListener(newInfoWindow, 'open', () => {
      setTimeout(()=>{
        const routeButton = document.querySelector('.routebutton');
        const shareButton = document.querySelector('.sharebutton');

        routeButton.addEventListener('click', () => {  
          if (isMobile) {
            const naverMapAppUrl = `nmap://route/car?dlat=${hotple.latitude}&dlng=${hotple.longitude}&dname=${hotple.name}&appname=hotple`;
            const naverMapAppStoreUrl = isIOS ? `http://itunes.apple.com/app/id311867728?mt=8`:`https://play.google.com/store/apps/details?id=com.nhn.android.nmap`; 

            if(platform!='web'){
              window.flutter_inappwebview.callHandler('CustomChannel', 'OPEN_NAVER_MAP', naverMapAppUrl, naverMapAppStoreUrl);
            }
            else{
              if(isAndroid){
                let fallbackTimer;

                const handleVisibilityChange = () => {
                  if (document.visibilityState === "hidden") {
                    clearTimeout(fallbackTimer);
                  }
                };

                document.addEventListener("visibilitychange", handleVisibilityChange);
                window.location.href = naverMapAppUrl;

                fallbackTimer = setTimeout(() => {
                  document.removeEventListener("visibilitychange", handleVisibilityChange);
                  window.location.href = naverMapAppStoreUrl;
                }, 1000);
              }
              else{
                /*let blurFlag = false;
                const handleBlur = () => {
                  blurFlag = true;
                  window.confirm('aaaa');
                  window.removeEventListener("blur", handleBlur);
                };
                window.addEventListener("blur", handleBlur);

                let visibilityFlag = false;
                const handleVisibilityChange = () => {
                  if (document.visibilityState === "hidden") {
                    visibilityFlag = true;
                  }
                  window.removeEventListener("visibilitychange", handleVisibilityChange);
                };
                document.addEventListener("visibilitychange", handleVisibilityChange);*/

                // 네이버 지도 앱 실행
                window.location.href = naverMapAppUrl;

                // Fallback 처리
                /*setTimeout(() => {
                  window.confirm('aaaaaaa');
                  if (document.visibilityState !== "hidden") {
                    // 페이지를 떠나지 않았으면 앱 실행 실패로 간주
                    window.confirm('bbbbbbbbbb');
                    window.open(naverMapAppStoreUrl);
                  }
                }, 1000); // 적절한 시간으로 설정*/
              }
            } 
          }
          else{
            const naverMapWebUrl = `https://map.naver.com/v5/directions/${devicePosition.long},${devicePosition.lat},내 위치/${hotple.longitude},${hotple.latitude},${hotple.name}/-/car`;
            window.open(naverMapWebUrl, '_blank');
          }
        });

        shareButton.addEventListener('click', async () => {
          await shareContent('HOTPLE 공유',`[HOTPLE 공유]\n지금 인스타에서 핫한 '${hotple.name}'\n이런 곳은 어때요? 😉\n\nhttps://hotple.today?menu=search&hotple_id=${hotple.hotple_id}`);
        });
      });
    });

    newInfoWindow.open(map, position);
    setInfoWindow(newInfoWindow);
  };

  const whenHotpleSelected = () => {
    showHotpleDetail(selectedHotple);
    setIsSearchMode(false);

    const markerPosition = new naver.maps.LatLng(selectedHotple.latitude, selectedHotple.longitude);
    const projection = map.getProjection();
    
    const point = projection.fromCoordToOffset(markerPosition);
    
    const newPoint = new naver.maps.Point(point.x, point.y + 90);
  
    const newPosition = projection.fromOffsetToCoord(newPoint);
    map.panTo(newPosition,{duration:500});
  };

  function formatDate(date) {
    const d = new Date(date);
    const year = String(d.getFullYear()).slice(-2);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  const createCustomMarker = (hotple) => {
    const { latitude, longitude, name, main_category, sub_category1, start_date, end_date } = hotple;

    const position = new naver.maps.LatLng(latitude, longitude);
    let marker_file="";

    switch(main_category) {
      case "음식점":
        marker_file="restaurant";
        break;
      case "카페디저트":
        marker_file="cafe";
        break;
      case "주점":
        marker_file="alcohol";
        break;
      case "팝업스토어":
        marker_file="popupStore";
        break;
      case "문화예술":
        marker_file="cultural";
        break;
      case "오락액티비티":
        marker_file="activity";
        break;
      case "자연힐링":
        marker_file="nature";
        break;
      default:
        marker_file="cultural";
    }

    let startDate = '';
    let endDate = '';

    if (start_date) 
      startDate = formatDate(start_date);
    if (end_date)
      endDate = formatDate(end_date);

    const content = `
      <div id='temp-marker' class='parent_marker'>
        <div class='div_marker'>
          <p class='p_marker'>${name}</p>
          <p class='p1_marker'>${sub_category1}</p>
          ${startDate!='' || endDate!='' ? `<p class='p2_marker'>${startDate} ~ ${endDate}</p>` : ''}
        </div>
        <img class='markercourseAddIcon' alt="" src="images/marker_${marker_file}.png" />
      </div>
    `;

    // 1. content를 임시로 DOM에 추가하여 width 측정
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    document.body.appendChild(tempDiv);

    const tempMarker = tempDiv.querySelector('#temp-marker');
    const contentWidth = tempMarker.offsetWidth;

    // 2. content의 width에 따라 anchor 좌표 계산
    const anchorX = contentWidth - 19;

    // 3. 임시 DOM 요소 제거
    document.body.removeChild(tempDiv);

    // 4. naver.maps.Marker 생성
    const marker = new naver.maps.Marker({
      position,
      map,
      icon: {
        content,
        anchor: new naver.maps.Point(anchorX, 43), // 동적으로 계산한 anchorX 값
      },
    });

    marker.hotple_id = hotple.hotple_id;

    naver.maps.Event.addListener(marker, 'click', () => {
      setSelectedHotple(hotple);
    });

    return marker;
  };

  const getRangeHotple = async (lat=null, long=null, range=null) => {
    try {
      setSelectedOption((prev)=>
        ({...prev,
          region: '위치'
        }));
      if(isMapLoad){
        let response;
        let latitude, longitude;

        const center = map.getCenter();
        latitude = lat || center.lat();
        longitude = long || center.lng();
        const searchRange = range || getMapRange()/2*1.2;

        response = await api.get(`/hotple/range`,{
          params: {
            category: selectedOption.category,
            range: searchRange,
            long: longitude,
            lat: latitude
          }
        });

        drawRangeCircle(latitude, longitude, searchRange);
        drawPositionMarker(latitude, longitude);

        setRangeHotples(response.data);
        
        const result = await api.get(`/gis/reverse_geocode`,{
          params: {
            lat: latitude,
            long: longitude
          }
        });

        if(result){
          setMapLocation((prev)=>
            ({...prev,
              address: result.data.address,
              latitude: latitude,
              longitude: longitude,
              name: (lat ? mapLocation.name : null )
            }));
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getRecommendHotple = async (lat=null, long=null) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const hotpleId = params.get('hotple_id');
      const region = params.get('region');
      const category = params.get('category');
      const menu = params.get('menu');
      let response;
      let latitude, longitude;

      latitude = lat || devicePosition.lat;
      longitude = long || devicePosition.long;

      if(menu == 'search'){ //공유 url을 타고 들어온 것이라면
        if(hotpleId){
          setSearchButtonFlag(true);
          response = await api.get(`/hotple/${hotpleId}`);
          if(response.data.hotples.length > 0){
            latitude = response.data.hotples[0].latitude;
            longitude = response.data.hotples[0].longitude;

            setRangeHotples(response.data);
            setTimeout(() => {
              setSelectedHotple(response.data.hotples[0]);
            }, 500);

            const result = await api.get(`/gis/reverse_geocode`,{
              params: {
                lat: latitude,
                long: longitude
              }
            });

            if(result){
              setMapLocation((prev)=>
                ({...prev,
                  address: result.data.address,
                  latitude: latitude,
                  longitude: longitude,
                  name: null
                }));
            }
          }
        }else{
          if(category)
            setSelectedOption((prev)=>({...prev, category: category}));
          if(region)
            setSelectedOption((prev)=>({...prev, region: region}));
        }

        const newUrl = window.location.pathname;
        window.history.replaceState({}, '', newUrl);
      }
      else{
        const searchRange = 20;

        response = await api.get(`/hotple/range`,{
          params: {
            category: selectedOption.category,
            range: searchRange,
            long: longitude,
            lat: latitude
          }
        });
        drawRangeCircle(latitude, longitude, searchRange);
        drawPositionMarker(latitude, longitude);

        setRecommendHotples(response.data);
        setActiveMenu('home')
        if(response.data.hotples.length>0 && map)
          map.morph(new naver.maps.LatLng(devicePosition.lat, devicePosition.long), 12);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getRegionHotple = async () => {
    try {
      if(isMapLoad){
        const response = await api.get(`/hotple/region`,{
          params: {
            category: selectedOption.category,
            region: selectedOption.region,
          }
        });

        deletePositionMarker();
        deleteRangeCircle();
        setRangeHotples(response.data);

        if(response.data.hotples.length==0){
          const coordinates = regionCoordinates[selectedOption.region];
          const [lat, lng] = coordinates;
          map.morph(new naver.maps.LatLng(lat, lng), 16);
        }
        else
          moveMapToShowAllMarkers(response.data.hotples);

        setMapLocation((prev)=>
          ({...prev,
            name: selectedOption.region
          }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getGroupHotples = async () => {
    try {
      if(isMapLoad){
        const response = await api.get(`/hotple/group`,{
          params: {
            category: selectedOption.category.replace('@',''),
            region: selectedOption.region,
          }
        });

        setMapLocation(
        (prev)=>({...prev,
          name: selectedOption.category.replace('@','')
        }));

        deletePositionMarker();
        deleteRangeCircle();
        setRangeHotples(response.data);

        if(response.data.hotples.length>0)
          moveMapToShowAllMarkers(response.data.hotples);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const moveMapToShowAllMarkers = (hotples) => {
    const bounds = new naver.maps.LatLngBounds();
    hotples.forEach(hotple => bounds.extend(new naver.maps.LatLng(hotple.latitude, hotple.longitude)));

    // 패딩을 주고 경계에 맞춰 카메라 이동
    map.fitBounds(bounds, {
      top: 40, // 패딩 (위쪽)
      right: 40, // 패딩 (오른쪽)
      bottom: 40, // 패딩 (아래쪽)
      left: 40 // 패딩 (왼쪽)
    });
  };

  const drawRangeCircle = (lat, long, radius) => {
    if(map){
      deleteRangeCircle();

      setRangeCircle(new naver.maps.Circle({
        map: map,
        center: new naver.maps.LatLng(lat, long),
        radius: radius*1000,
        strokeColor: '#ff0069',
        strokeWeight: 2,
      }));
    }
  }

  const deleteRangeCircle = () => {
    if(rangeCircle){
      rangeCircle.setMap(null);
      const svgElements = document.querySelectorAll('svg'); // 모든 <svg> 태그 선택
      svgElements.forEach(svg => svg.remove()); // 각 <svg> 태그를 순회하며 삭제
    }
  }

  const deletePositionMarker = () => {
    if (positionMarker) {
      positionMarker.setMap(null); // 기존 마커가 존재하면 지도에서 제거
      setPositionMarker(null); // 상태 초기화
      
      const markerElement = document.getElementById('marker');
      if (markerElement) {
        markerElement.remove(); // DOM에서 해당 요소를 제거
      }
    }
  };

  const drawPositionMarker = (lat, long) => {
    if(map){
      deletePositionMarker(); // 기존 마커 삭제

      setPositionMarker(new naver.maps.Marker({
        position: new naver.maps.LatLng(lat, long),
        map: map, // 지도를 가리키는 변수 (props나 state로 전달된 지도를 사용)
        icon: {
          content: `
            <div id="marker" style="
              width: 10px;
              height: 10px;
              background-color: #ff0069;
              border-radius: 50%;
              border: 2px solid white;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
            "></div>
          `,
          anchor: new naver.maps.Point(7, 7) // 마커의 앵커를 중앙으로 설정
        }
      })); // 새로운 마커를 상태에 저장
    }
  };

  const adjustMarkersPosition = (hotples) => {
    const OFFSET = 0.00003;
    const positionMap = {};
  
    hotples.forEach((hotple) => {
      if(hotple.latitude && hotple.longitude){
        const key = `${hotple.latitude.toFixed(6)}_${hotple.longitude.toFixed(6)}`;
        if (!positionMap[key]) {
          positionMap[key] = [];
        }
        positionMap[key].push(hotple);
      }
    });
  
    const adjustedHotples = [];
  
    Object.values(positionMap).forEach((hotpleList) => {
      if (hotpleList.length === 1) {
        adjustedHotples.push(hotpleList[0]);
      } else {
        hotpleList.forEach((hotple, index) => {
          const adjustedHotple = { ...hotple };
          adjustedHotple.latitude += OFFSET * index;  // index에 따라 위치 변경
          adjustedHotple.longitude += OFFSET * index;
          adjustedHotples.push(adjustedHotple);
        });
      }
    });
  
    return adjustedHotples;
  };

  const showMapHotples = (hotples) => {
    if (!map) return;

    clearMarkers();
    const newMarkers = adjustMarkersPosition(hotples).map(hotple => createCustomMarker(hotple));

    setMarkers(newMarkers);

    // 클러스터링 적용
    if (cluster) {
      cluster.setMap(null); // 기존 클러스터 삭제
    }

    const newCluster = new MarkerClustering({
      map: map,
      markers: newMarkers,
      disableClickZoom: false, // 클러스터 클릭 시 줌 인 가능
      gridSize: 100,
      minClusterSize: 2,
      maxZoom: 14,
      icons: [
        {
          content: `<div class="cluster cluster-1"></div>`,
          size: new naver.maps.Size(40, 40),
          anchor: new naver.maps.Point(20, 20)
        },
        {
          content: `<div class="cluster cluster-2"></div>`,
          size: new naver.maps.Size(50, 50),
          anchor: new naver.maps.Point(25, 25)
        },
        {
          content: `<div class="cluster cluster-3"></div>`,
          size: new naver.maps.Size(60, 60),
          anchor: new naver.maps.Point(30, 30)
        }
      ],
      indexGenerator: [5, 10, 20],
      stylingFunction: (clusterMarker, count) => {
        const countDiv = clusterMarker.getElement().querySelector('div');
        if (countDiv) {
          countDiv.textContent = count;
        }
      }
    });

    setCluster(newCluster);
  };

  useEffect(() => {
    if (!initialRender) {
      if(selectedOption.category.startsWith('@')){  //기간제 그룹 카테고리 선택이라면...
        if(activeMenu!='search')
          setActiveMenu('search');
        else
          getGroupHotples();
      }
      else{
        if(activeMenu=='search')
          if(mapLocation.name == selectedOption.region) //지역 검색 중이라면...
            getRegionHotple();
          else
            getRangeHotple();
        /*else if(activeMenu=='my' || activeMenu=='admin')
          showMapHotples(mapHotples.hotples)*/
        else if(activeMenu=='home')
          getRecommendHotple();
      }
    }
    else{
      setInitialRender(false);
    }
  }, [selectedOption.category]);

  useEffect(() => {
    if(selectedOption.region!='위치'){
      if(activeMenu=='search'){
        if(selectedOption.category.startsWith('@'))
          getGroupHotples();
        else
          getRegionHotple();
      }
      else if(activeMenu=='my' || activeMenu=='admin'){
        setMapLocation((prev)=>
          ({...prev,
            name: selectedOption.region
          }));
      }
    }
  }, [selectedOption.region]);

  useEffect(() => {
    if (infoWindow)
      infoWindow.close();

    if(activeMenu=='search'){
      if(selectedOption.category.startsWith('@'))
        getGroupHotples();
      else if(mapLocation.name == selectedOption.region)
        getRegionHotple();
      else
        getRangeHotple();
    }
    else if(activeMenu=='my' || activeMenu=='admin'){
      setSelectedOption((prev)=>({...prev, region: '전국'}));
      setMapLocation(
        (prev)=>({...prev,
          name: '전국'
        }));
      deleteRangeCircle();
      deletePositionMarker();
      const params = new URLSearchParams(window.location.search);
      const hotpleId = params.get('hotple_id');
      const menu = params.get('menu');
      if(!(menu=="my" && hotpleId))
        moveMapToShowAllMarkers(myHotples.hotples)
    }
    else if(activeMenu=='home'){
      getRecommendHotple();
      setMapLocation(
        (prev)=>({...prev,
          name: '내 주변'
        }));
    }
  }, [activeMenu]);

  useEffect(() => {
    showMapHotples(mapHotples.hotples);
  }, [mapHotples]);

  useEffect(() => {
    if (isMapLoad && map && mapElement) {
      let startTime = Date.now();

      const syncSize = () => {
        const currentTime = Date.now();

        if (currentTime - startTime > 5000)
          return true;

        if (mapElement.current && map) {
          const width = mapElement.current.offsetWidth;
          const height = mapElement.current.offsetHeight;
          const currentSize = map.getSize();

          if (currentSize.width !== width || currentSize.height !== height) {
            map.setSize(new naver.maps.Size(width, height));
            return false;
          }

          return true;
        }

        return false;
      };

      const interval = setInterval(() => {
        const isSynced = syncSize();
        if (isSynced)
          clearInterval(interval);
      }, 100);
    }
  }, [isMapLoad]);

  useEffect(() => {
    if (isMapLoad && map) {
      naver.maps.Event.addListener(map, 'click', () => {
          setIsSearchMode(false);
          setSelectedHotple(null);
          if (infoWindow)
              infoWindow.close();
      });

      naver.maps.Event.addListener(map, 'drag', () => {
          setIsSearchMode(false);
          setSearchButtonFlag(true);
          setSelectedHotple(null);
          if (infoWindow)
              infoWindow.close();
      });

      naver.maps.Event.addListener(map, 'zoom_changed', () => {
        setSearchButtonFlag(true);
        setSelectedHotple(null);
        if (infoWindow)
            infoWindow.close();
      });
    }
  }, [isMapLoad, infoWindow]);

  useEffect(() => {
    if (isMapLoad && map) {
      if(mapLocation.name!=null){
        if(mapLocation.name != '내 주변' && !periodHotpleGroup.some(group => group.name === mapLocation.name)){
          if(mapLocation.name != selectedOption.region){  //지역 버튼 선택으로 name이 바뀐 것이 아니라면...
            setIsSearchMode(false);
            const { latitude, longitude } = mapLocation;
            deletePositionMarker();
            map.setZoom(12, false);
            map.panTo(new naver.maps.LatLng(latitude, longitude));
            if(activeMenu=="search")
              getRangeHotple(latitude,longitude,20);
            else if(activeMenu=='my' || activeMenu=='admin')
              drawPositionMarker(latitude, longitude);
          }
          else
            deletePositionMarker();
        }
      }
    }
  }, [mapLocation.name]);

  useEffect(() => {
		if(isSearchMode){
      setSelectedHotple(null);
      if (infoWindow)
        infoWindow.close();
		}
	}, [isSearchMode])

  useEffect(() => {
    if (selectedHotple != null) {
      whenHotpleSelected();

      for(const marker of markers){
        if(marker.hotple_id === selectedHotple.hotple_id)
          marker.setZIndex(100);
        else
          marker.setZIndex(50);
      }
    } else {
      if (infoWindow) infoWindow.close();
    }
  }, [selectedHotple, markers]);

  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Escape')
          setIsSearchMode(false);
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };

  }, []);

  return (
    <div ref={mapElement} className="map">
      <MapHeader activeMenu={activeMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} mapHotples={mapHotples} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode} setInfoModalPage={setInfoModalPage} periodHotpleGroup={periodHotpleGroup} marginTop={marginTop}/>
      {isSearchMode && <SearchHeader selectedOption={selectedOption} setSelectedOption={setSelectedOption} setMapLocation={setMapLocation} setIsSearchMode={setIsSearchMode} marginTop={marginTop}/>}
      {(activeMenu=='search' || activeMenu == 'home') && searchButtonFlag &&
        <div className="buttonMap_searchBT" onClick={()=>whenSearchButtonClicked()}>
          <img className="iconrefresh_searchBT" alt="" src="images/refresh.svg" />
          <div className="label_searchBT">현 지도에서 탐색</div>
        </div>
      }

      <div className="locationMapControl" onClick={()=>moveToCurrentLocation()}>
        <img className="iconmyLocation" alt="" src="images/my_location.svg" />
      </div>

      <div className="zoomMapControl">
        <div className="plusButton" onClick={()=>increaseZoomLevel()}>
          <img className="iconplusLine" alt="" src="images/plus_line.svg" />
        </div>
        <div className="zoombuttondivider" />
          <div className="minusButton" onClick={()=>decreaseZoomLevel()}>
        <img className="iconminusLine" alt="" src="images/minus_line.svg" />
        </div>
      </div>
    </div>
  );
};

export default NaverMap;
