import { useEffect, useState, useRef } from 'react';
import styles from './NoticeModal.module.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NoticeModal = ({ setInfoModalPage, popupNoticeList }) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [shownNoticeList, setShownNoticeList] = useState([]);

    const settings = {
        infinite: false,
        speed: 200,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // 슬라이드가 바뀌기 직전에 인디케이터 변경
    };

    useEffect(() => {
        if(popupNoticeList && popupNoticeList.length > 0) 
            setShownNoticeList(popupNoticeList);
    }, [popupNoticeList]);

    const handleDoNotShowFor3Days = () => {
        const noticeId = shownNoticeList[currentSlide].id;
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 3);

        localStorage.setItem(`popup_${noticeId}`, expiryDate.toISOString());

        if(shownNoticeList.length==1)
            setInfoModalPage(0);

        setShownNoticeList((prev) => prev.filter((_, index) => index !== currentSlide));
    };

    const handleContentsClicked = () => {
        if (shownNoticeList[currentSlide]) {
            if(shownNoticeList[currentSlide].notice_url.includes('hotple.today'))
                window.location.href = shownNoticeList[currentSlide].notice_url;
            else{
                window.open(shownNoticeList[currentSlide].notice_url, '_blank', 'noopener,noreferrer');

                if(currentSlide==shownNoticeList.length-1)
                    setInfoModalPage(0);

                sliderRef.current.slickNext()
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    <Slider ref={sliderRef} {...settings} className={styles.usageSlider}>
                        {shownNoticeList.map(notice => (
                            <div>
                                <img 
                                    className={styles.contentImage} 
                                    alt="" 
                                    src={notice.popup_image_url}
                                    onClick={handleContentsClicked}
                                />
                            </div>
                            )
                        )}
                    </Slider>

                    <div className={styles.indicators}>
                        <div className={styles.cancleButton} onClick={() => sliderRef.current.slickPrev()}>
                            <div className={styles.text}>◀</div>
                        </div>
                        {shownNoticeList.map((_,index) => (
                            <div
                                key={index}
                                className={`${styles.indicator} ${currentSlide === index ? styles.activeIndicator : ''}`}
                            ></div>
                        ))}
                        <div className={styles.deleteButton} onClick={() => sliderRef.current.slickNext()}>
                            <div className={styles.text}>▶</div>
                        </div>
                    </div>

                    <div className={styles.buttons}>
                        <div className={styles.negativeButton} onClick={handleDoNotShowFor3Days}>
                            <div className={styles.text}>3일간 보지않기</div>
                        </div>
                        <div className={styles.positiveButton} onClick={handleContentsClicked}>
                            <div className={styles.text1}>자세히 보러가기</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoticeModal;
