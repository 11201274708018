import styles from './UserModal.module.css';
import DeleteHotpleModal from './DeleteHotple/DeleteHotpleModal';
import AddedHotpleModal from './AddedHotple/AddedHotpleModal';
import PermissionModal from './Permission/PermissionModal';


const UserModal = ({userModalPage, setUserModalPage, modalProps, myHotples, updateHotpleInfo}) => {
  	return (
        <div className={styles.modalbackground}>
            <div className={styles.modalcontainer}>
                {userModalPage==1 &&<DeleteHotpleModal setUserModalPage={setUserModalPage} modalProps={modalProps} updateHotpleInfo={updateHotpleInfo}/>}
                {userModalPage==2 &&<AddedHotpleModal setUserModalPage={setUserModalPage} modalProps={modalProps} myHotples={myHotples} updateHotpleInfo={updateHotpleInfo}/>}
                {userModalPage==3 &&<PermissionModal setUserModalPage={setUserModalPage} modalProps={modalProps} updateHotpleInfo={updateHotpleInfo}/>}

                {userModalPage==1 &&
                    <div className={styles.closebutton} onClick={()=>setUserModalPage(0)}>
                        <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                    </div>
                }
            </div>
        </div>
    );
};
    
export default UserModal;
  