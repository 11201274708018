import React, { useRef, useState } from 'react';
import styles from './UsageModal.module.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const UsageModal = () => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        infinite: false,
        speed: 200,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // 슬라이드가 바뀌기 직전에 인디케이터 변경
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    <Slider ref={sliderRef} {...settings} className={styles.usageSlider}>
                        <div>
                            <img 
                                className={styles.contentImage} 
                                alt="" 
                                src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/1.png"
                            />
                        </div>
                        <div>
                            <img 
                                className={styles.contentImage} 
                                alt="" 
                                src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/2.png"
                            />
                        </div>
                        <div>
                            <img 
                                className={styles.contentImage} 
                                alt="" 
                                src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/3.png"
                            />
                        </div>
                        <div>
                            <img 
                                className={styles.contentImage} 
                                alt="" 
                                src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/4.png"
                            />
                        </div>
                        <div>
                            <img 
                                className={styles.contentImage} 
                                alt="" 
                                src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/5.png"
                            />
                        </div>
                        <div>
                            <img 
                                className={styles.contentImage} 
                                alt="" 
                                src="https://hotple.s3.ap-northeast-2.amazonaws.com/usage/6.png"
                            />
                        </div>
                    </Slider>

                    <div className={styles.indicators}>
                        <div className={styles.cancleButton} onClick={() => sliderRef.current.slickPrev()}>
                            <div className={styles.text}>◀</div>
                        </div>
                        {[0,1,2,3,4,5].map((index) => (
                            <div
                                key={index}
                                className={`${styles.indicator} ${currentSlide === index ? styles.activeIndicator : ''}`}
                            ></div>
                        ))}
                        <div className={styles.deleteButton} onClick={() => sliderRef.current.slickNext()}>
                            <div className={styles.text}>▶</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsageModal;
