import api from '../../../../api';
import styles from './InquiryMonitorModal.module.css';
import React, { useEffect, useState } from 'react';

const InquiryMonitorModal = ({ setInfoModalPage }) => {
    const [inquiries, setInquiries] = useState([]);
    const [filteredInquiries, setFilteredInquiries] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortColumn, setSortColumn] = useState('');
    const [filters, setFilters] = useState({ type: '', is_processed: '', id: '', username: ''});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [responseId, setResponseId] = useState(null);

    const handleAnswerButtonClicked = (id) => {
        setResponseId(id); // 어떤 ID에서 호출했는지 저장
        setIsModalOpen(true); // 모달 열기
    };

    const handleConfirm = async () => {
        if(inputValue.length>0){
            try {
                const response = await api.post(`/admin/inquiry/${responseId}/answer`,{answer:inputValue});
                setInquiries((prevInquiries) =>
                    prevInquiries.map((inquiry) =>
                        inquiry.id === responseId ? response.data : inquiry
                    )
                );

                setFilteredInquiries((prevFilteredInquiries) =>
                    prevFilteredInquiries.map((inquiry) =>
                        inquiry.id === responseId ? response.data : inquiry
                    )
                );
            } catch(error){
                console.error('Error fetching inquiries:', error);
            }

            setIsModalOpen(false); // 모달 닫기
            setInputValue(""); // 입력값 초기화
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false); // 모달 닫기
        setInputValue(""); // 입력값 초기화
    };

    const fetchAllInquiries = async () => {
        try {
            const response = await api.get('/admin/inquiry/');
            if (response.status === 200) {
                setInquiries(response.data);
                setFilteredInquiries(response.data);
            } else {
                console.error('Failed to fetch inquiries');
            }
        } catch (error) {
            console.error('Error fetching inquiries:', error);
        }
    };

    const clearFilter = (column) => {
        const newFilters = { ...filters, [column]: '' };
        setFilters(newFilters);
        handleFilterChange({ target: { value: '' } }, column);
    };

    useEffect(() => {
        fetchAllInquiries();
    }, []);

    // 테이블 정렬 처리 함수
    const handleSort = (column) => {
        const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        setSortColumn(column);
    
        const sortedUsers = [...filteredInquiries].sort((a, b) => {
            if (column === 'created_at' || column === 'updated_at') {
                // 가입일 기준 정렬
                const dateA = new Date(a[column]);
                const dateB = new Date(b[column]);
                return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            } else {
                // 그 외의 컬럼 기준 정렬
                if (a[column] < b[column]) return newSortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return newSortOrder === 'asc' ? 1 : -1;
                return 0;
            }
        });
    
        setFilteredInquiries(sortedUsers);
    };

    async function isProcessedChanged(id, is_processed) {
        try {
            const response = await api.patch(`/admin/inquiry/${id}`,{is_processed});
            setInquiries((prevInquiries) =>
                prevInquiries.map((inquiry) =>
                    inquiry.id === id ? response.data : inquiry
                )
            );

            setFilteredInquiries((prevFilteredInquiries) =>
                prevFilteredInquiries.map((inquiry) =>
                    inquiry.id === id ? response.data : inquiry
                )
            );
        } catch(error){
            console.error('Error fetching inquiries:', error);
        }
    }

    async function handleDeleteInquiryButton(id) {
        try {
            const confirmDelete = window.confirm(`이 문의를 영구 삭제하시겠습니까?`);
    
            if (confirmDelete) {
                const response = await api.delete(`/admin/inquiry/${id}`);

                if(response){
                    setInquiries((prevInquiries) =>
                        prevInquiries.filter((inquiry) => inquiry.id != id)
                    );
        
                    setFilteredInquiries((prevFilteredInquiries) =>
                        prevFilteredInquiries.filter((inquiry) => inquiry.id != id)
                    );
                }
            }
        } catch(error){
            console.error('Error delete inquiries:', error);
        }
    }

    // 필터링 처리 함수
    const handleFilterChange = (e, column) => {
        const newFilters = { ...filters, [column]: e.target.value };
        setFilters(newFilters);
    
        const filtered = inquiries.filter(inquiry => {
            const idMatch = newFilters.id
                ? inquiry.id.toString() === newFilters.id // 정확히 일치하는 값만 필터링
                : true;


            const typeMatch = column === 'type'
                ? inquiry.type?.toLowerCase().includes(newFilters.type.toLowerCase())
                : true;

            const instagramUsernameMatch = column === 'username'
                ? inquiry.username?.toLowerCase().includes(newFilters.username.toLowerCase())
                : true;

            const isProcessedMatch = column === 'is_processed'
                ? inquiry.is_processed?.toString().includes(newFilters.is_processed)
                : true;
    
            return (
                idMatch &&
                instagramUsernameMatch &&
                typeMatch &&
                isProcessedMatch
            );
        });
    
        // 필터가 비어 있으면 전체 데이터 다시 보여줌
        if (Object.values(newFilters).every(value => value === '')) {
            setFilteredInquiries(inquiries);
        } else {
            setFilteredInquiries(filtered);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    {isModalOpen && (
                        <div
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                zIndex: 100,
                                width: "100vw",
                                height: "100vh",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "16px",
                                    borderRadius: "8px",
                                    width: "300px",
                                    display: "flex",
                                    gap: "16px",
                                    flexDirection: "column",
                                }}
                            >
                                <div className={styles.text}>답변 입력</div>
                                <textarea
                                    rows={5}
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder="여러 줄 입력 가능"
                                    style={{ height: "250px"}}
                                />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button onClick={handleCancel} style={{ marginRight: "10px" }}>
                                        취소
                                    </button>
                                    <button onClick={handleConfirm}>답변 전송</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <b className={styles.title}>
                        <p className={styles.p1}>
                            <span>문의 관리</span>
                        </p>
                    </b>

                    <div className={styles.tableFrame}>
                        <div className={styles.userTableFrame}>
                            <div className={styles.dataCount}>데이터 수: {filteredInquiries.length}개</div>
                            <table className={styles.userTable}>
                                <thead>
                                    <tr>
                                        <th className={styles.id} onClick={() => handleSort('id')}>#</th>
                                        <th className={styles.ids} onClick={() => handleSort('type')}>타입</th>
                                        <th className={styles.count} onClick={() => handleSort('username')}>인스타 ID</th>
                                        <th className={styles.count} onClick={() => handleSort('is_processed')}>처리 여부</th>
                                        <th className={styles.count} onClick={() => handleSort('text')}>문의</th>
                                        <th className={styles.count} onClick={() => handleSort('answer')}>답변</th>
                                        <th className={styles.date} onClick={() => handleSort('created_at')} >문의일</th>
                                        <th className={styles.date} onClick={() => handleSort('updated_at')} >완료일</th>
                                        <th className={styles.date} onClick={() => handleSort('updated_at')} >삭제</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className={styles.id}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.id} onChange={(e) => handleFilterChange(e, 'id')} />
                                                {filters.id && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('id')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.ids}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.type} onChange={(e) => handleFilterChange(e, 'type')} />
                                                {filters.type && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('type')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.ids}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.username} onChange={(e) => handleFilterChange(e, 'username')} />
                                                {filters.username && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('username')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.ids}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.is_processed} onChange={(e) => handleFilterChange(e, 'is_processed')} />
                                                {filters.is_processed && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('is_processed')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.count}></th>
                                        <th className={styles.count}></th>
                                        <th className={styles.date}></th>
                                        <th className={styles.date}></th>
                                        <th className={styles.count}></th>
                                    </tr>

                                    {filteredInquiries.map(inquiry => (
                                        <tr key={inquiry.id}>
                                            <td className={styles.id}>{inquiry.id}</td>
                                            <td className={styles.ids}>{inquiry.type}</td>
                                            <td className={styles.ids}>
                                                {inquiry.username ?
                                                    <a className={styles.instagramUsername} href={`https://instagram.com/${inquiry.username}/`} target="_blank" rel="noopener noreferrer">@{inquiry.username}</a>
                                                :
                                                    <>-</>
                                                }
                                            </td>
                                            <td className={styles.count}>
                                                <input type="checkbox" id={inquiry.id} checked={inquiry.is_processed} onChange={(e) => isProcessedChanged(inquiry.id, e.target.checked)} />
                                            </td>
                                            <td className={styles.count}>{inquiry.text}</td>
                                            {
                                                inquiry.is_processed?
                                                <td className={styles.count}>{inquiry.answer}</td>
                                                :
                                                <button className={styles.answerButton} onClick={()=>handleAnswerButtonClicked(inquiry.id)}>답변</button>
                                            }
                                            <td className={styles.date}>{new Date(inquiry.created_at).toLocaleString()}</td>
                                            <td className={styles.date}>{inquiry.is_processed?new Date(inquiry.updated_at).toLocaleString():null}</td>
                                            <td className={styles.date}><img src="/images/close_line.svg" className={styles.clearButton2} onClick={() => handleDeleteInquiryButton(inquiry.id)}/></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttonFrame}>
                <div className={styles.confirmButton} onClick={()=>setInfoModalPage(0)}>
                    <div className={styles.text}>확인했어요!</div>
                </div>
            </div>
        </div>
    );
};

export default InquiryMonitorModal;
